import {fillordersActions, fillOrderSubscribers} from './redux';
import {wetsideSubscribers} from '../wetside/redux/wetside'

export default function registerServices(bottle, initialState) {
  bottle.decorator("emitter", fillOrderSubscribers.bind(null, bottle.container));
  bottle.serviceFactory('fillordersActions', fillordersActions, 'apiClient', 'commonActions');
  bottle.factory('fillordersActions@FillOrders', (c) => {
    return Object.assign({
      setGrowerForStats: c.commonActions.setGrowerForStats,
    }, c.fillordersActions);
  });
  bottle.factory('fillordersComponentActions@FilteredBinListItem', (c) => {
    return Object.assign({
      selectBin: c.wetsideActions.selectBin,
      removeSelectedBin: c.wetsideActions.removeSelectedBin,
      setGrowerForStats: c.commonActions.setGrowerForStats,
    }, c.fillordersActions);
  });

  return bottle;
}
