import {FullPanel, PanelBody, PanelHeader} from "../../common/component/panel";
import React from "react";
import {connect} from 'react-redux';
import {getFilteredBinList} from "../selector/filtered-bin-list";
import {getBinsAreLoaded, getBinsById, getSelectedBinId} from "../../common/selector/bin";
import {getCommonDataIsLoaded, getFieldsById, getGrowersById, getVarietiesById} from "../../common/selector/common";
import {InlineSpinner} from "../../common/component/Spinner";
import GrowerColumnWrapper from "../../common/component/GrowerColumnWrapper";
import {getBinMoisture, formatHoursInBinLot} from "../../bin/model/bin";
import classNames from 'classnames';
import bindContainerActions from "../../bindContainerActions";
import Weight from "../../common/component/Weight";

const FilteredBinListItem = React.forwardRef(function FilteredBinListItem({bin, binLot, grower, field, variety, lockBin, selectBin, removeSelectedBin, isSelected, filteredBinListClickHandler, setGrowerForStats}, ref) {
  function onMouseEnter(e) {
    selectBin(bin.id, e);
    setGrowerForStats(grower.id);
  }
  function onMouseLeave(e) {
    removeSelectedBin(e);
    setGrowerForStats(null);
  }
  return <div ref={ref} className={classNames("FilteredBinListItem row align-items-center", isSelected && "locked")} style={{cursor: "pointer", background: !binLot.poundsNotRequested && "lightgray"}} onClick={filteredBinListClickHandler.bind(null, binLot.id)} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
    <div className="col flex-grow-0">
      <div style={{width: "26px"}} className="text-right"><b>{bin.id}</b></div>
    </div>
    <div className="col flex-grow-0">
      <GrowerColumnWrapper color={grower.color} className="py-0">
        <div><b>{grower.name}</b></div>
      </GrowerColumnWrapper>
    </div>
    <div className="col">{field.name}</div>
    <div className="col">{variety.name}</div>
    <div className="col">
      <div style={{width: "28px"}} className="text-right">{getBinMoisture(bin)}</div>
    </div>
    <div className="col">
     <div className={"rounded " + ((bin.airDoorOpen && bin.binMoisture <= 8) ? "bg-danger" : (bin.airDoorOpen && bin.binMoisture >= 8)  ? "bg-success" : "bg-secondary")} style={{height: 14, width: 14}}/>
    </div>
    <div className="col">
      <Weight pounds={binLot.poundsFull} units/>
    </div>
    <div className="col">
      <div>{formatHoursInBinLot(binLot.hoursInBin)}</div>
    </div>
    <div className="col" style={{color: !binLot.poundsNotRequested && "red"}}>
      <Weight pounds={binLot.poundsNotRequested} units/>
    </div>
  </div>
});

class ScrollIntoViewFilteredBinListItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <FilteredBinListItem {...this.props} />
  }
}

const FilteredBinListItemContainer = connect((state, {binLot}) => {
  const bin = getBinsById(state)[binLot.bin];
  return {
    bin,
    grower: getGrowersById(state)[binLot.grower],
    field: getFieldsById(state)[binLot.field],
    variety: getVarietiesById(state)[binLot.variety],
    isSelected: getSelectedBinId(state) === bin.id,
  };
}, bindContainerActions('fillordersComponentActions@FilteredBinListItem'))(ScrollIntoViewFilteredBinListItem);

function FilteredBinListSection({bins, dataIsLoaded}) {
  return <FullPanel className="d-flex flex-column">
    <PanelHeader title="Filtered Bin List" divider={true}/>
    <PanelBody style={{overflowY: "scroll"}}>
      <InlineSpinner enabled={!dataIsLoaded}>
        {!bins.length && <p className="text-center m-2">All bins are currently empty.</p>}
        {bins.map((item, index) => {
          return <FilteredBinListItemContainer key={index} binLot={item} />
        })}
      </InlineSpinner>
    </PanelBody>
  </FullPanel>
}

export default connect((state) => {
  return {
    bins: getFilteredBinList(state),
    dataIsLoaded: getCommonDataIsLoaded(state) && getBinsAreLoaded(state),
  };
})(FilteredBinListSection);
