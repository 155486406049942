import React, { useEffect } from 'react';
import FillOrderPanelSection from "./FillOrderPanelSection";
import {withRouter, Link} from 'react-router-dom'
import {getCurrentFillOrder, getIsFillOrderSaving} from "../selector/fillorders";
import compose from 'lodash/fp/compose';
import {connect} from 'react-redux';
import bindContainerActions from "../../bindContainerActions";
import GrowerColumnWrapper from "../../common/component/GrowerColumnWrapper";
import {percentToPounds} from "../../bin/model/bin";
import {mapLbsReq} from "./FillOrderCollapse";
import {withMobileSize} from "../../common/component/size";
import get from 'lodash/get';
import size from 'lodash/size';
import Weight from "../../common/component/Weight";
import Switch from "../../common/component/Switch";
import { URL, URLSearchParams } from 'whatwg-url';
import {
  getFieldsById,
  getGrowersById,
  getReceiversById,
  getStorageBinsById,
  getVarietiesById
} from "../../common/selector/common";
import {InlineSpinner} from "../../common/component/Spinner";
import {getBinsById} from "../../common/selector/bin";
import {FillOrderPage} from "../redux";

export function GrowerHeader({grower, id, variety}){
  let currentWindow = window.location.pathname;
  return (
    <div className="flex-grow-1">
      <div className="row mb-2 justify-content-between">
        <span className="p-2"><b>#{id} {grower}</b></span>
        <span className="p-2"><b>{variety}</b> </span>
        {!currentWindow.includes("edit") && <Link to={`/fill-orders/edit/${id}`} type="button" className="btn btn-warning">Update</Link>}
      </div>
    </div>
  )
}

export function GrowerSubHeader({fillOrderInfo, lbsreq, lbsrem}){
  const printLink = new URL(process.env.REACT_APP_API_BASE_URI+'/print-fillorder');
  printLink.search = '?' + new URLSearchParams({
    bin_names: (fillOrderInfo.binNames || []).join(', '),
    storage_name: fillOrderInfo.storageBin,
    fillorder_id: fillOrderInfo.id,
    grower_name: fillOrderInfo.grower,
    field_name: fillOrderInfo.field,
    variety: fillOrderInfo.variety,
    fillorder_lbs: lbsreq,
  }).toString();

  return (
    <div className="flex-grow-1">
      <div className="row mb-2 justify-content-between">
        <span className="col"><b>{fillOrderInfo.field}</b></span>
        <span className="col"><b>{fillOrderInfo.receiver}</b></span>
        {fillOrderInfo.storageBin &&
          <a href={printLink.toString()} target="__blank">
            <button type="button" className="btn btn-success p-1">Print</button>
          </a>
        }
      </div>
      <div className="row mb-2 justify-content-between">
        {fillOrderInfo.storageBin && <span className="col">
          <b>Storage Bin: </b>
          <span className="row m-auto">{fillOrderInfo.storageBin} </span>
        </span>}
        <span className="col">
          <b>Lbs Requested </b>
          <Weight pounds={lbsreq} units line/>
        </span>
        <span className="col">
          <b>Lbs Remaining </b>
          <Weight pounds={lbsrem} units line/>
        </span>
      </div>
    </div>
  )
}

function PercentInput({value, item, index, stepUpdateFillOrderItemPercentRequested, sliderUpdateFillOrderItemPercentRequested, isMobile}){
  const percentRequestedOnKeyUp = (e) => {
    if (e.keyCode === 38) {
      stepUpdateFillOrderItemPercentRequested('up', index);
    } else if (e.keyCode === 40) {
      stepUpdateFillOrderItemPercentRequested('down', index);
    }
  };
  const updateSlider = () => {
    let percent = parseInt(document.getElementById(`percentSliderInput${index}`).value);
    sliderUpdateFillOrderItemPercentRequested(percent, index)
  }
  let maxPercent = item.binLot.percentFull;
  return (
    !isMobile ?
    <input type="text" tabIndex={1} className="form-control p-1 text-center"  value={value} onKeyUp={percentRequestedOnKeyUp} style={{height: 'auto', width:"25%"}}/> :
    <div className="text-center">
      {value}
      <input id={`percentSliderInput${index}`} type="range" className="form-control custom-range p-1 text-center" min={0} max={maxPercent} placeholder={value} step="10" onChange={updateSlider} style={{height: 'auto'}}/>
    </div>
  )
}

function GrowerItem({item, index, binsById, varietiesById, fieldsById, moveItemFillOrder, moveItemBackFillOrder, currentFillOrder, removeFillOrderItem, stepUpdateFillOrderItemPercentRequested, sliderUpdateFillOrderItemPercentRequested, isMobile, history}) {
  const remove = () => removeFillOrderItem(item.id, history, item.binLotId);
  const moveItem = () => {
    if(item.state !== "complete") {
      moveItemFillOrder(currentFillOrder, item.id);
    } else {
      moveItemBackFillOrder(currentFillOrder.id, item.id)
    }
  };

  let currentWindow = window.location.pathname;
  return (
    <div className="row mb-2 align-items-center" key={index}>
      <div className="col">{get(binsById, [item.binLot.bin, 'binNum'], 'N/A')}</div>
      <div className="col">{get(varietiesById, [item.binLot.variety, 'name'], 'N/A')}</div>
      <div className="col">{get(fieldsById, [item.binLot.field, 'name'], 'N/A')}</div>
      {!currentWindow.includes("edit") ?
        <Weight pounds={item.poundsRequested} units/> :
        <PercentInput type="text" tabIndex={1} className="form-control col-xs-2 p-1 text-center" item={item} index={index} value={percentToPounds(item.percentRequested)}
        stepUpdateFillOrderItemPercentRequested={stepUpdateFillOrderItemPercentRequested} sliderUpdateFillOrderItemPercentRequested={sliderUpdateFillOrderItemPercentRequested}
        isMobile={isMobile} style={{height: 'auto'}}/>}
      <div className="col">0.0%</div>
      {!currentWindow.includes("edit") && <Switch on={item.state === "complete" ? true : false} onClick={moveItem}/>}
      <button type="button" className="btn btn-danger p-1 m-1" onClick={remove}>Remove</button>
    </div>
  )
}

const GrowerItemContainer = compose(
  withMobileSize,
  connect((state) => {
    return {
      currentFillOrder: getCurrentFillOrder(state),
      binsById: getBinsById(state),
      fieldsById: getFieldsById(state),
      varietiesById: getVarietiesById(state),
    };
  }, bindContainerActions('fillordersActions')))(GrowerItem);

export function GrowerItems({items = [], currentFillOrder, moveAllFillOrderItems, history, isSavingFillOrder}) {
  const moveAll = () => moveAllFillOrderItems(currentFillOrder);
  return (
    <div>
      <div className="row mb-2 justify-content-between">
        <div className="col"><b>Bin</b></div>
        <div className="col"><b>Variety</b></div>
        <div className="col"><b>Field</b></div>
        <div className="col"><b>Lbs Requested</b></div>
        <div className="col"><b>Last</b></div>
        {moveAllFillOrderItems && <div className="col"><b>Move All</b>
          <Switch on={currentFillOrder.state === "complete" ? true : false} onClick={moveAll} disabled={isSavingFillOrder} />
        </div>}
      </div>
      {items.map((item, index) => <GrowerItemContainer key={index} index={index} item={item} history={history} />)}
    </div>
  )
}

export function creatFillOrderSectionMouseEventHandlers(currentFillOrderInfo, setGrowerForStats) {
  function onMouseEnter() {
    if (currentFillOrderInfo && currentFillOrderInfo.growerId) { setGrowerForStats(currentFillOrderInfo.growerId); }
  }
  function onMouseLeave() {
    if (currentFillOrderInfo && currentFillOrderInfo.growerId) { setGrowerForStats(null); }
  }

  return {onMouseEnter, onMouseLeave};
}

function ShowFillOrderSection({history, currentFillOrder, currentFillOrderInfo, moveAllFillOrderItems, setGrowerForStats, loadCurrentFillOrder, initFillOrderPage}) {
  const fillOrder = currentFillOrderInfo;

  // reload the fill order on render
  useEffect(() => {
    const cancelToken = loadCurrentFillOrder(fillOrder.id)[1];
    initFillOrderPage(FillOrderPage.Show);
    return () => cancelToken.cancel();
  }, [fillOrder.id]);

  const {onMouseEnter, onMouseLeave} = creatFillOrderSectionMouseEventHandlers(currentFillOrderInfo, setGrowerForStats);

  let lbs = mapLbsReq(get(currentFillOrder, "items"));
  return <FillOrderPanelSection title={`Fill Order #${fillOrder.id}`} backUrl="/fill-orders" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
    <InlineSpinner enabled={!currentFillOrder}>{() => (
      <React.Fragment>
        <GrowerColumnWrapper color={fillOrder.color}>
          <GrowerHeader grower={fillOrder.grower} id={currentFillOrder.id} variety={fillOrder.variety}/>
        </GrowerColumnWrapper>
        <GrowerSubHeader fillOrderInfo={fillOrder} lbsreq={lbs.poundsRequested} lbsrem={lbs.poundsRemaining}/>
        <GrowerItems items={fillOrder.items} currentFillOrder={currentFillOrder} moveAllFillOrderItems={moveAllFillOrderItems} history={history} />
      </React.Fragment>
    )}</InlineSpinner>
  </FillOrderPanelSection>
}

export function mapStateToFillOrderSectionProps(state, {match}) {
  const currentFillOrder = getCurrentFillOrder(state);
  const growersById = getGrowersById(state);
  const varietiesById = getVarietiesById(state);
  const fieldsById = getFieldsById(state);
  const storageBinsById = getStorageBinsById(state);
  const receiversById = getReceiversById(state);
  const binsById = getBinsById(state);

  const grower = currentFillOrder && size(growersById) ? growersById[currentFillOrder.grower] : null;
  const field = currentFillOrder && size(fieldsById) ? fieldsById[get(currentFillOrder, "items[0].binLot.field")] : null;
  const variety = currentFillOrder && size(varietiesById) ? varietiesById[currentFillOrder.variety] : null;
  const storageBin = currentFillOrder && size(storageBinsById) ? storageBinsById[currentFillOrder.storageBin] : null;
  const receiver = currentFillOrder && size(receiversById) ? receiversById[currentFillOrder.receiver] : null;
  const isFillOrderSaving = getIsFillOrderSaving(state);

  const currentFillOrderInfo = {
    id: parseInt(match.params.fillOrderId),
    grower: grower && grower.name,
    growerId: grower && grower.id,
    color: grower && grower.color,
    variety: variety && variety.name,
    field: field && field.name,
    storageBin: storageBin && storageBin.name,
    receiver: receiver && receiver.name,
    items: get(currentFillOrder, "items"),
    binNames:  get(currentFillOrder, ["items"], []).map(item => get(binsById, [item.binLot.bin, 'binNum'], 'N/A')),
  };

  return {
    currentFillOrder,
    currentFillOrderInfo,
    isFillOrderSaving
  };
}

export default compose(
  connect(mapStateToFillOrderSectionProps, bindContainerActions('fillordersActions@FillOrders')),
  withRouter,
  withMobileSize
)(ShowFillOrderSection);
